.faqQuestion {
  display: flex;
  align-items: center; // Alinea verticalmente el icono con el texto
  width: 100%;
  padding: 16px;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f9f9f9;
  }

  .icon {
    margin-right: 10px; // Espacio entre el icono y el texto
    font-size: 20px; // Ajusta el tamaño del icono si es necesario
    display: flex;
    align-items: center;
  }
}

.faqAnswer {
  padding: 0 16px;
  background-color: #fff;
  font-size: 16px;
  color: #333;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out, padding 0.3s ease-out;

  &.faqAnswerActive {
    max-height: 300px; // Asegúrate de que el valor sea suficiente para mostrar el contenido
    opacity: 1;
    padding: 16px; // Agrega padding solo cuando está activo
  }
}
